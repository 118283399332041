<template>
  <section class="game-list-container" :class="{'top': top, 'bottom': bottom}">
    <article class="title">
      <h1>{{title}}</h1>
    </article>

    <article class="container-list">
      <slot/>
    </article>
  </section>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      top: {
        type: Boolean
      },
      bottom: {
        type: Boolean
      }
    },
    data() {
      return {
        test: ['Amandine', 'Natacha', 'Lucas']
      }
    },
  }
</script>

<style lang="scss" scoped>
.game-list-container {
  background-color: #E46B65;
  min-width: 400px;
  padding: 20px 40px;
  padding-top: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  @media screen and (max-width: 1400px) {
    width: 100%;
    min-width: unset;
    padding: 20px 20px;
  }
}

.title {
  background-color: #BB4943;
  padding: 15px 30px;
  border-radius: 25px;
  text-align: center;
  margin-top: -25px;
  font-size: 25px;

  @media screen and (max-width: 1400px) {
    margin-top: 10px;
  }
}

.container-list {
  margin-top: 25px;
  overflow: auto;
  height: 80vh;

  @media screen and (max-width: 1400px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    height: unset;
  }
}

@media screen and (max-width: 1400px) {
  .top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>