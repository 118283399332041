<template>
  <section class="player-card">
    <img v-if="checkIfExist()" :src="require(`../assets/${playerData.place.toLowerCase()}.png`)" />
    <img v-else src="../assets/generic.png" />

    <h2 v-if="playerData.place !== 'Espion' && !checkIfExist()" id="place">{{playerData.place}}</h2>
    <h2 v-if="playerData.place !== 'Espion'" id="role">{{playerData.role}}</h2>
  </section>
</template>

<script>
export default {
  props: {
    playerData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      places: [
        'espion', 
        'carnaval', 
        'cirque', 
        'supermarché', 
        'université', 
        'plage', 
        'ecole', 
        'studio de cinéma', 
        'casino', 
        'ambassade', 
        'garage', 
        'centre de thalasso', 
        'sous-marin', 
        'paquebot', 
        'banque', 
        'station spatiale', 
        'avion',
        'base militaire',
        'boîte de nuit',
        'hôpital',
        'hôtel',
        'restaurant',
        'station polaire',
        'théâtre',
        'train de voyageur',
        'parc d\'attraction',
        'poste de police',
        'croisade',
        'fête d\'entreprise',
        'zoo'
      ]
    }
  },
  methods: {
    checkIfExist() {
      return this.places.includes(this.playerData.place.toLowerCase()) ? true : false
    }
  }
}
</script>

<style lang="scss">
  .player-card {
    position: relative;
    display: flex;
    justify-content: center;
    img {
      width: 650px;
      height: 450px;
      
      @media screen and (max-width: 1400px) {
        width: 400px;
        height: 300px;
      }
      
      @media screen and (max-width: 600px) {
        width: 300px;
        height: 220px;
      }
    }

    #place {
      font-size: 25px;
      font-weight: 600;
      position: absolute;
      left: 300px;
      top: 125px;

      @media screen and (max-width: 1400px) {
        font-size: 18px;
        left: 185px;
        top: 85px;
      }

      @media screen and (max-width: 600px) {
        font-size: 12px;
        left: 135px;
        top: 60px;
      }
    }
    #role {
      font-size: 35px;
      position: absolute;
      left: 155px;
      bottom: 135px;

      @media screen and (max-width: 1400px) {
        font-size: 24px;
        left: 95px;
        bottom: 90px;
      }

      @media screen and (max-width: 600px) {
        font-size: 16px;
        left: 70px;
        bottom: 65px;
      }
    }
  }
</style>
