<template>
  <section class="notes" :style="open ? 'height: 276px' : 'height: 50px'">
    <h3 @click="open = !open">Notes</h3>
    <textarea />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        open: false
      }
    },
  }
</script>

<style lang="scss" scoped>
.notes {
  background-color: #ecea89;
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 25px;
  cursor: pointer;
  // height: 276px;
  transition: 0.5s all;

  h3 {
    font-size: 20px;
    text-align: center;
    color: black;
    font-weight: 500;
  }
  textarea {
    margin-top: 10px;
    resize: none;
    color: black;
    height: 200px;
    border-radius: 10px;
    width: 100%;
    padding: 5px;
    height: 200px;
  }
}
</style>