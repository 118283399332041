<template>
  <section class="h-screen game-container">
    <game-list title="Joueurs" class="players h-screen game-list" top>
      <div v-for="(player, index) in players" :key="index">
        <game-list-row :style="index === 0 ? 'margin: 0' : ''">{{player.pseudo}} <i v-if="player.host" class="material-icons">star</i></game-list-row>
      </div>
    </game-list>

    <article class="flex flex-col items-center">
      <img class="logo" src="../assets/logo_off.png" />

      <player-card v-if="playerData && !$store.state.gameInProgress" :playerData="playerData" />
      <p v-if="!$store.state.gameInProgress" class="text-xl mb-8">{{displayTimer}}</p>
      <h1 class="mb-8" v-else>En attente de la fin de la partie ...</h1>

      <button v-if="$store.state.host" class="bg-green-500 p-3 mb-5 w-1/2 mb-10 text-white text-lg rounded-md" @click="handleRestart()">Rejouer</button>

      <notes class="notes" />
    </article>
    
    <game-list title="Lieux" class="game-list" bottom>
      <div v-for="(place, index) in places" :key="index">
        <game-list-row :style="index === 0 ? 'margin: 0' : ''">{{place.name}}</game-list-row>
      </div>
    </game-list>
  </section>
</template>

<script>
import PlayerCard from '../components/PlayerCard.vue';
import GameList from '../components/GameList'
import GameListRow from '../components/GameListRow'
import Notes from '../components/Notes'
import { mapActions } from 'vuex'

export default {
  components: {
    PlayerCard,
    GameList,
    GameListRow,
    Notes
  },
  data() {
    return {
      pseudo: '',
      players: [],
      places: [],
      playerData: null,
      timer: null,
      displayTimer: "15:00"
    };
  },
  computed: {
    socket () {
      return this.$store.state.socket
    }
  },
  methods: {
    ...mapActions(['setHost', 'setGameInProgress']),
    handleRestart() {
      this.socket.emit('RESTART_GAME')
    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timer = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        
        this.displayTimer = minutes + ":" + seconds;

        if (--timer < 0) {
            clearInterval(this.timer)
            this.displayTimer = "00:00"
        }
      }, 1000);
    }
  },
  mounted() {
    if (!this.socket) {
      this.$router.push('/login')
      return
    }

    this.socket.emit('INIT_GAME');
    this.socket.on('GAME', (game) => {
      this.players = game.players
      this.places = game.places
      this.playerData = { place: game.place.name, role: game.place.role }
      clearInterval(this.timer)
      this.startTimer(60 * 15)
    });
    this.socket.on('LIST_PLAYERS', (players) => { this.players = players });

    this.socket.on('HOST', () => { this.setHost() });
    this.socket.on('RESTART', () => {
      this.setGameInProgress(false) 
      this.socket.emit('INIT_GAME')
    })
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: 1400px) {
    flex-direction: column;
    overflow: visible;
  }

  i {
    color: yellow;
    margin-left: 5px;
  }
}

.players {
  @media screen and (max-width: 1400px) {
    display: none;
  }
}

.game-list {
  margin-top: 100px;
  height: calc(100vh - 100px);
  @media screen and (max-width: 1400px) {
    margin-top: 0;
    width: 100vw;
    height: unset;
  }
}

.logo {
  width: 400px!important;
  @media screen and (max-width: 1400px) {
  width: 200px!important;
    margin-top: 0;
  }
}

.notes {
  position: absolute;
  bottom: -15px;
  min-height: 60px;
  width: 300px;
  left: 420px;
}

@media screen and (max-width: 1400px) {
  .notes {
    display: none;
  }
}
</style>
