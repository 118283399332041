<template>
  <section @click="crossOut = !crossOut">
    <p :class="{'cross-out': crossOut}"><slot/></p>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        crossOut: false
      }
    },
  }
</script>

<style lang="scss" scoped>
.cross-out {
  text-decoration: line-through;
  color: rgb(174, 33, 33);
}

p {
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  }
</style>